<template>
    <li
        class="sidebar__nav-item"
        :data-title="data.name"
        v-if="!data.soon && !isSpecialLink && isLoaded"
    >
        <nav-link
            :data="data"
            v-if="!data.children.length"
        />

        <dropdown
            v-else
            active-class="sidebar__nav-item--expanded"
            :opened="isOpened"
            :styles="{
                    transformOrigin: 'top',
                    transition: 'transform .4s ease-in-out'
                }"
            animation="slide"
        >
            <template v-slot:header>
                <nav-link
                    :data="data"
                    @toggleDropdown="toggleDropdown"
                />
            </template>
            <template v-slot:body>
                <div class="sidebar__nav-dropdown">
                    <nav-item
                        v-for="item in data.children"
                        :key="item.id"
                        :data="item"
                    />
                </div>
            </template>
        </dropdown>

        <span class="sidebar__badge" v-if="data.soon">Скоро</span>
    </li>
    <nav-tree
        v-else-if="isSpecialLink && data.name === 'База знаний' && isLoaded"
        :data="{
            ...data,
            id: knowledgeBaseId
        }"
        :object_id="objectIdSection"
        :key="tree_section || 'База знаний'"
    />
    <nav-tree-doc
        v-else-if="isSpecialLink && data.name === 'Документы' && isLoaded"
        :data="data"
        :object_id="objectIdDocument"
        url_list="/api/v1/rubric_document/"
        :key="tree_document || 'Документы'"
    />
</template>

<script>
import {eventBus} from "@/main";
import { mapGetters } from 'vuex'
import NavTreeDoc from '@/components/sidebar/tree/NavTreeDoc'
import NavTree from '@/components/knowledge_base/menu/NavTree'
import Dropdown from '@/components/Dropdown'
import NavLink from './NavLink'
import NavList from './NavList'

export default {
    name: "NavItem",
    components: {
        NavTreeDoc,
        NavTree,
        Dropdown,
        NavLink,
        NavList
    },
    props: ['data'],
    data() {
        return {
            objectIdDocument: 0,
            objectIdSection: 0,
            tree_document: null,
            tree_section: null,
            specialLinks: ['База знаний', 'Документы'],
            isLoaded: false,
            isOpened: false
        }
    },
    methods: {
        toggleDropdown() {
          this.isOpened = !this.isOpened
        }
    },
    computed: {
        ...mapGetters('default_data', [
            'knowledgeBaseId'
        ]),
        isSpecialLink () {
            return this.specialLinks.includes(this.data.name)
        }
    },
    mounted() {
        this.isLoaded = true;
        eventBus.$on('item-open-rubric-knowledge-base', rubricId => {
            this.objectIdSection = rubricId;
        })
        eventBus.$on('rubric-update-knowledge-base', event => {
            this.tree_section = this.$uuid.v1();
        })
        eventBus.$on('item-open-rubric-document', rubricId => {
            this.objectIdDocument = rubricId;
        })
        eventBus.$on('rubric-update-document', event => {
            this.tree_document = this.$uuid.v1();
        })
    },
}
</script>

<style lang="scss">
@import "#sass/v-style";

.sidebar__nav {
    &-item {
        display: flex;
        align-items: center;
        border-radius: 4px;
        transition: background-color $ease;

        &:hover {
            background-color: var(--nav-hover-color);
            .sidebar__badge {
                border-color: #555;
            }
        }

        &--expanded {
            padding-bottom: 10px;
            .sidebar__nav {
                &-item {
                    margin-left: 20px;

                    &:hover {
                        background-color: #616575;
                    }

                    &:first-child {
                        margin-top: 10px;
                    }
                }
                &-dropdown {
                    .sidebar__nav {
                        &-item {
                            margin-left: 30px;
                            font-size: 0.875rem;
                        }
                        &-text {
                            margin-left: 5px;
                        }
                        &-icon {
                            display: none;
                        }
                        &-expand {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &-link {
        color: #FFF;
        &:hover {
            color: #FFF;
        }
    }
}
</style>
