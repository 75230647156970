<template>
    <div
        class="sidebar-tree__wrap"
    >
        <dropdown
            active-class="sidebar-tree__wrap--opened"
            :opened="isOpened"
            :key="data.name"
            :styles="{
                transformOrigin: 'top',
                transition: 'transform .4s ease-in-out'
            }"
            animation="slide"
            :on-first-open="loadAll"
        >
            <template v-slot:header>
                <tree-header-doc
                    :data="data"
                    class="tree-header--root"
                    @toggleDropdown="toggleDropdown"
                />
            </template>

            <template v-slot:body>
                <div class="sidebar__nav-dropdown">
                    <tree-item-doc
                        v-for="(variable, index) in documentItems.all"
                        :key="variable.children ? `rubric_${variable.id}` : `material_${variable.id}`"
                        :data="variable"
                        :isIndex="index"
                        :isDragged="isDragged"
                        :isMaterial="variable.children ? false : true"
                        @change-dragged="changeDragged"
                    />
                </div>
            </template>
        </dropdown>
    </div>
</template>

<script>
import session from '@/api/session'
import { mapActions, mapGetters } from 'vuex'
import { eventBus } from '@/main'

export default {
    name: 'NavTreeDoc',
    components: {
        TreeItemDoc: () => import('@/components/sidebar/tree/TreeItemDoc'),
        TreeHeaderDoc: () => import('@/components/sidebar/tree/TreeHeaderDoc'),
        NavLink: () => import('@/components/sidebar/navigation/NavLink'),
        Dropdown: () => import('@/components/Dropdown')
    },
    props: {
        data: {
            type: Object
        },
        object_id: {
            required: false
        },
        url_list: {
            type: String,
            default: '/api/v1/rubric/'
        }
    },
    data() {
        return {
            is_admin: true,
            copy_rubrics: [],
            rubrics: [],
            list: [],
            resultListArray: [],
            isOpened: false,
            objectId: null,
            isDragged: false
        }
    },
    created() {
        const data = [{ link: '/knowledge', name: 'База знаний' }, { link: '/documents', name: 'Документы' }]
        data.forEach(item => {
            if (window.location.href.includes(item.link) && this.data.name === item.name) {
                this.isOpened = true
                if (window.location.hash) {
                    this.objectId = Number(+window.location.hash.slice(1))
                }
                this.loadAll()
            }
        })
    },
    watch: {
        object_id: {
            handler: function(val, oldVal) {
                this.itemActive()
            },
            deep: true
        },

        documentItems: {
            handler: function(val, oldVal) {
                this.resultListArray = val.all
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters('documents_data', ['documentItems'])
    },
    methods: {
        ...mapActions('documents_data', ['setDocuments']),

        toggleDropdown() {
            this.isOpened = !this.isOpened
        },
        async loadAll() {
            await session
                .get(this.url_list)
                .then(response => {
                    this.objectId = parseInt(this.object_id)
                    const list = response.data.list
                    list.forEach(item => {
                        item.active = item.id === this.objectId
                    })
                    this.is_admin = response.data.is_admin
                    this.list = list
                    const tree = response.data.rubrics

                    const dataCollection = (items, parent_id) => {
                        items.forEach(item => {
                            item.active = false
                            item.parent_id = parent_id
                            item.list.forEach(obj => {
                                obj.active = false
                                if (obj.id === this.objectId) {
                                    obj.active = true
                                    item.active = true
                                    this.findItemActive(tree, item.parent_id, response.data.rubrics)
                                }
                            })
                            dataCollection(item.children, item.id)
                        })
                    }
                    dataCollection(tree, null)
                    this.rubrics = tree
                    if (this.objectId) {
                        this.itemActive()
                    }

                    this.setDocuments({
                        rubrics: this.rubrics,
                        list: this.list
                    })
                })
                .catch(() => {
                })
        },
        itemActive() {
            function emptyActive(items) {
                items.forEach(item => {
                    item.active = false
                    emptyActive(item.children)
                })
            }

            emptyActive(this.rubrics)

            this.findItemActive(this.rubrics, this.object_id, this.rubrics)
        },
        findItemActive(items, item_id, rubrics) {
            items.forEach(item => {
                if (item.id === item_id) {
                    item.active = true
                    this.findItemActive(rubrics, item.parent_id, rubrics)
                } else if (item.children.length > 0) {
                    this.findItemActive(item.children, item_id, rubrics)
                }
            })
        },
        changeDragged(value) {
            this.isDragged = value
        }
    },
    mounted() {
        eventBus.$on('load-documents-list', rubricId => {
            this.loadAll()
        })
        if (this.list) {
            this.list.forEach(item => {
                const element = this.$refs['item_' + item.id][0]
                if (element.offsetWidth < element.scrollWidth) {
                    element.title = item.title
                }
            })
        }
    },
}
</script>

<style lang="scss">
@import "#sass/v-style";
.sidebar-tree {
    &__wrap {
        border-radius: 4px;
        transition: background-color $ease;
        &:hover {
            background-color: var(--nav-hover-color);
        }
        &--opened {
            background-color: #494D5A;
            padding-bottom: 10px;
            &:hover {
                background: #494D5A;
            }
            & > .dropdown__header .tree-header__expand-icon {
                transform: rotate(90deg);
            }
        }
    }
}
</style>
