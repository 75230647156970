var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-item",class:{
        'd-none': !_vm.isVisible && !_vm.data.published
    }},[(!_vm.data.have_children)?_c('tree-header',{class:{
            'tree-header--draft': _vm.isVisible && !_vm.data.published
        },staticStyle:{"margin-left":"20px"},attrs:{"data":_vm.data,"materialIndex":_vm.materialIndex,"isDragged":_vm.isDragged,"is_material":true},on:{"change-dragged":function($event){return _vm.$emit('change-dragged', $event)}}}):_c('dropdown',{key:((_vm.data.title) + "_" + (_vm.data.id) + "_" + (_vm.data.active)),staticStyle:{"padding-left":"20px"},attrs:{"active-class":"sidebar-tree__wrap--opened","opened":_vm.opened,"styles":{
            transformOrigin: 'top',
            transition: 'transform .4s ease-in-out'
        },"animation":"slide","on-first-open":_vm.loadChildren},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tree-header',{class:{
                    'tree-header--draft':
                        _vm.isVisible && !_vm.data.published
                },attrs:{"data":_vm.data,"materialIndex":_vm.materialIndex,"isDragged":_vm.isDragged,"is_material":true},on:{"toggleDropdown":_vm.toggleDropdown,"change-dragged":function($event){return _vm.$emit('change-dragged', $event)}}})]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"sidebar__nav-dropdown"},_vm._l((_vm.data.children),function(child,index){return _c('tree-item',{key:("child_" + (child.id)),attrs:{"data":child,"materialIndex":index,"is_material":true,"isDragged":_vm.isDragged},on:{"change-dragged":function($event){return _vm.$emit('change-dragged', $event)}}})}),1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }