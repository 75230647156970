<template>
  <router-link
    tag="a"
    class="sidebar__nav-link"
    :to="data.link"
  >
    <i
      class="sidebar__nav-expand"
      :style="`opacity: ${data.children.length ? 1 : 0}`"
      @click="$emit('toggleDropdown')"
    />
    <img class="sidebar__nav-icon" :src="imgUrl" />
    <span class="sidebar__nav-text">{{ data.name }}</span>
  </router-link>
</template>

<script>
export default {
  name: 'NavLink',
  props: ['data'],
  computed: {
    imgUrl () {
      return this.data.icon
        ? require('assets/img/favicons/sidebar/' + this.data.icon)
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.sidebar__nav {
  &-link {
    display: flex;
    align-items: center;
    padding: $nav-item-padding;
    width: 100%;
  }
  &-text {
    margin-left: 10px;
  }
  &-expand {
    @include icon($sidebar-nav-expand, 10px);
    transform: rotate(0);
    margin-right: 10px;
    transition: transform $ease;
    &::before {
        display: none;
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        height: 20px;
        width: 20px;
        background: #616575;
        border-radius: 2px;
        transition: background $ease;
        opacity: .6;
    }
    &:hover {
        &::before {
            display: block;
        }
    }
  }
  &-icon {
    $icon-size: 13px;
    width: $icon-size;
    height: $icon-size;
    display: block;
  }
  &-item {
    &--expanded {
      .sidebar__nav-expand {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
