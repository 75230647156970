<template>
    <div class="tree-header">
        <div
            class="tree-header__hr"
            :class="{
                'tree-header__hr--hover': isDragOver
            }"
        >
            <div class="tree-header__hr-round" />
        </div>
        <div
            @drop="onDrop($event, data, materialIndex)"
            @dragover.prevent="onDragOver($event)"
            @dragleave="onDragLeave($event)"
            @dragenter.prevent
            :style="{ visibility: isDragged ? 'visible' : 'hidden' }"
            class="tree-header__dropped-zone"
        />
        <div
            class="tree-header__expand"
            @click="$emit('toggleDropdown')"
            :style="{
                visibility:
                    specialLinks.includes(data.name) || data.have_children
                        ? 'visible'
                        : 'hidden'
            }"
        >
            <i class="tree-header__expand-icon" />
        </div>

        <span
            class="tree-header__link"
            :draggable="data.id && data.title && isPowersAdmin"
            @dragstart="
                startDrag($event, data, materialIndex)
                disableBg()
            "
            @dragover.prevent="onDragOverElem($event)"
            @dragleave="onDragLeaveElem($event)"
            @dragend="onDragEnd()"
            @drop="onDrop($event, data, materialIndex)"
            @dragenter.prevent
        >
            <div
                class="tree-header__link-background"
                :class="{
                    'tree-header__link-background--drop':
                        isDragOverElem && data.id && data.parent
                }"
            ></div>
            <div
                class="tree-header__icon"
                :class="{
                    'tree-header__icon--root': specialLinks.includes(data.name)
                }"
                @click.stop="openPicker"
                @mouseover="disableBg"
                @mouseleave="enableBg"
            >
                <div v-if="data.required" class="tree-header__emoji">
                    <icon width="12" height="14">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 0.5C0 0.223857 0.223858 0 0.5 0H11.1667C11.4428 0 11.6667 0.223858 11.6667 0.5V13.5C11.6667 13.7761 11.4428 14 11.1667 14H0.5C0.223858 14 0 13.7761 0 13.5V0.5ZM5.83333 8.16667C5.189 8.16667 4.66667 7.64433 4.66667 7V3.5C4.66667 2.85567 5.189 2.33333 5.83333 2.33333C6.47766 2.33333 7 2.85567 7 3.5V7C7 7.64433 6.47767 8.16667 5.83333 8.16667ZM5.83333 9.625C5.189 9.625 4.66667 10.1473 4.66667 10.7917C4.66667 11.436 5.189 11.9583 5.83333 11.9583C6.47767 11.9583 7 11.436 7 10.7917C7 10.1473 6.47767 9.625 5.83333 9.625Z"
                            fill="#FBC04F"
                        />
                    </icon>
                </div>
                <div v-else-if="data.emoji" class="tree-header__emoji">
                    <emoji :emoji="data.emoji" set="apple" :size="16" />
                </div>
                <img v-else class="tree-header__img" :src="imgUrl || icon" />
            </div>
            <router-link
                tag="a"
                :to="data.link || `/knowledge/article/${data.id}`"
                class="tree-header__title"
                v-tooltip="
                    !isDragged
                        ? {
                              content: data.title,
                              placement: 'top',
                              offset: 10
                          }
                        : null
                "
                draggable="false"
            >
                {{ data.title || data.name }}
            </router-link>
            <button
                v-if="!isSimpleLink && isPowersAdmin"
                :disabled="triggerAddDisabled"
                :to="`/knowledge/add/${data.id}`"
                @click="addMaterial"
                class="tree-header__add"
            >
                <icon width="10" height="10">
                    <path
                        d="M1 5H9"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    /><path
                        d="M5 9L5 1"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </icon>
            </button>
        </span>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex'

    import Material from 'assets/img/favicons/material.svg'
    import Rubric from 'assets/img/favicons/rubric.svg'
    import session from '@/api/session'
    import { eventBus } from '@/main'
    import { Emoji } from 'emoji-mart-vue'
    import Icon from '@/components/icon/Icon'
    import { useBroadcastChannel } from 'vue-composable';

    export default {
        name: 'TreeHeader',
        props: {
            data: {
                type: Object
            },
            materialIndex: {
                type: Number
            },
            is_material: {
                required: false
            },
            isSimpleLink: {
                type: Boolean,
                default: false
            },
            isDragged: {
                type: Boolean
            },
            opened: {
                type: Boolean,
                required: false
            }
        },
        components: {
            Icon,
            Emoji
        },
        data() {
            return {
                navItemBg: '#494D5A',
                Material,
                Rubric,
                specialLinks: ['База знаний', 'Документы'],
                emojiIcon: this.data.emoji,
                dragObject: {},
                isDragOver: false,
                isDragOverElem: false,
                isPowersAdmin: false,
                children: [],
                oldMaterialChildren: [],
                newMaterialChildren: [],
                fetchData: [],
                triggerAddDisabled: false,
                broadCastMaterial: {
                    supported: null,
                    data: { value: null },
                    send: null
                }
            }
        },
        watch: {
            'broadCastMaterial.data.value'(newValue, oldValue) {
                this.MATERIALS_LIST(newValue);
            }
        },
        computed: {
            ...mapGetters('default_data', ['current_user', 'isAdmin', 'knowledgeBaseId']),
            ...mapState('knowledge_base', ['materialsList', 'prevAddedMaterialId']),

            imgUrl() {
                return this.data.icon
                    ? require('assets/img/favicons/sidebar/' + this.data.icon)
                    : null
            },
            icon() {
                return this.is_material ? this.Material : this.Rubric
            }
        },
        methods: {
            ...mapMutations('knowledge_base', ['MATERIALS_LIST']),
            search(data, id) {
                let found = data.find(d => d.id === id)
                if (!found) {
                    let i = 0
                    while (!found && i < data.length) {
                        if (data[i].children && data[i].children.length) {
                            found = this.search(data[i].children, id)
                        }
                        i++
                    }
                }
                return found
            },
            async fetch(array) {
                await session.post(
                    '/api/v1/knowledge-base/drag_and_drop/',
                    array
                )
            },
            getFetchData(item, material) {
                if (this.isDragOver) {
                    return [
                        {
                            parent_id: item.parent,
                            children: this.newMaterialChildren.map(item => {
                                return {
                                    id: item.id,
                                    position: item.position
                                }
                            })
                        },
                        item.parent !== material.parent
                            ? {
                                  parent_id: material.parent,
                                  children: this.oldMaterialChildren.map(
                                      item => {
                                          return {
                                              id: item.id,
                                              position: item.position
                                          }
                                      }
                                  )
                              }
                            : {}
                    ]
                }
                return [
                    {
                        parent_id: item.id,
                        children: this.newMaterialChildren.map(item => {
                            return {
                                id: item.id,
                                position: item.position
                            }
                        })
                    },
                    item.id !== material.id
                        ? {
                              parent_id:
                                  material.parent || this.knowledgeBaseId,
                              children: this.oldMaterialChildren.map(item => {
                                  return {
                                      id: item.id,
                                      position: item.position
                                  }
                              })
                          }
                        : {}
                ]
            },
            getMaterialParent(materialParent) {
                materialParent
                    ? (this.oldMaterialChildren = materialParent.children
                          .sort((a, b) => a.position - b.position)
                          .map((item, index) => {
                              return { ...item, position: index }
                          }))
                    : (this.oldMaterialChildren = this.materialsList
                          .sort((a, b) => a.position - b.position)
                          .map((item, index) => {
                              return { ...item, position: index }
                          }))
            },
            async addMaterial() {
                this.triggerAddDisabled = true

                if (this.prevAddedMaterialId) {
                    let material = {}
                    await session.get(`/api/v1/material/${this.prevAddedMaterialId}/`)
                        .then(request => {
                            material = request.data
                        })
                        .catch(error => {
                            console.error(error)
                        })

                    if (material.text === '<h1>Без названия</h1>') {
                        await session.delete(`/api/v1/material/${this.prevAddedMaterialId}/`)
                            .then((request) => {
                                this.$store.dispatch(
                                    'knowledge_base/removeMaterial',
                                    {
                                        ...material,
                                        parent:
                                            material.parent ===
                                            this.knowledgeBaseId
                                                ? this.knowledgeBaseId
                                                : material.parent
                                    }
                                )
                            })
                            .catch((error) => {
                                console.error(error)
                            })
                    }
                }

                const form = {
                    parent: this.data.id,
                    text: '<h1>Без названия</h1>',
                    title: 'Без названия',
                    is_draft: true,
                    published: false,
                    have_children: false,
                    children: [],
                    timepiece: 20
                }

                await session.post('/api/v1/material/', form)
                    .then((request) => {
                        const materialId = request.data.id
                        this.$store.dispatch('knowledge_base/setPrevMaterialId', materialId)
                        this.$store.dispatch('knowledge_base/addMaterial', {
                            ...form,
                            id: materialId
                        })

                        this.$router.push(`/knowledge/edit/${materialId}`)
                    })
                    .catch((error) => {
                        console.error(error)
                    })

                this.triggerAddDisabled = false
            },
            getNewChildren(children, newMaterial, index) {
                const firstPartChildren = children
                    .filter(item => item.position < index)
                    .map(item => {
                        return { ...item, position: item.position - 1 }
                    })
                const secondPartChildren = children
                    .filter(item => item.position >= index)
                    .map(item => {
                        return { ...item, position: item.position + 1 }
                    })
                secondPartChildren.push(newMaterial)

                const newChildren = firstPartChildren
                    .concat(secondPartChildren)
                    .sort((a, b) => a.position - b.position)
                    .map((item, index) => {
                        return { ...item, position: index }
                    })
                return newChildren
            },
            async intoMaterial(item, material, index) {
                this.$store.dispatch('knowledge_base/removeMaterial', material)
                const materialParent = this.search(
                    this.materialsList,
                    +material.parent
                )
                const parent = this.search(this.materialsList, +item.id)
                let newMaterial
                if (!parent.children.length) {
                    const request = await session.get(
                        `/api/v1/knowledge-base/${parent.id}/children/`
                    )
                    const indexLastChildren = request.data.length
                    if (indexLastChildren) {
                        this.$store.dispatch('knowledge_base/addChildren', {
                            children: request.data,
                            parent: parent.id
                        })
                    }
                    newMaterial = {
                        ...material,
                        position: indexLastChildren,
                        parent: item.id
                    }
                } else {
                    newMaterial = {
                        ...material,
                        position: parent.children.length,
                        parent: item.id
                    }
                }

                parent.children.push(newMaterial)
                parent.have_children = true
                this.getMaterialParent(materialParent)
                this.newMaterialChildren = parent.children
                this.fetchData = this.getFetchData(item, material)
            },
            nearbyMaterial(item, material, index) {
                this.$store.dispatch('knowledge_base/removeMaterial', material)
                const materialParent = this.search(
                    this.materialsList,
                    +material.parent
                )
                const parent = this.search(this.materialsList, +item.parent)
                const newMaterial = {
                    ...material,
                    position: index,
                    parent: item.parent
                }
                if (item.parent !== material.parent) {
                    if (item.parent === this.knowledgeBaseId) {
                        this.MATERIALS_LIST(
                            this.materialsList
                                .sort((a, b) => a.position - b.position)
                                .map((item, index) => {
                                    return { ...item, position: index }
                                })
                        )
                    } else {
                        parent.children = parent.children
                            .sort((a, b) => a.position - b.position)
                            .map((item, index) => {
                                return { ...item, position: index }
                            })
                    }
                }
                this.getMaterialParent(materialParent)
                if (parent) {
                    this.newMaterialChildren = this.getNewChildren(
                        parent.children,
                        newMaterial,
                        index
                    )
                    parent.children = this.newMaterialChildren
                } else {
                    this.newMaterialChildren = this.getNewChildren(
                        this.materialsList,
                        newMaterial,
                        index
                    )
                    this.MATERIALS_LIST(this.newMaterialChildren)
                }
                this.fetchData = this.getFetchData(item, material)
            },
            startDrag(event, item, index) {
                this.$emit('change-dragged', true)
                event.dataTransfer.dropEffect = 'move'
                event.dataTransfer.effectAllowed = 'move'
                const jsonItem = JSON.stringify(item)
                event.dataTransfer.setData('material', jsonItem)
            },
            async onDrop(event, item, index) {
                const material = JSON.parse(
                    event.dataTransfer.getData('material')
                )
                const isChildren = Boolean(
                    this.search(material.children, item.id)
                )
                if (
                    item.id &&
                    item.parent &&
                    item.id !== material.id &&
                    !isChildren
                ) {
                    if (this.isDragOver) {
                        await this.nearbyMaterial(item, material, index)
                    } else {
                        if (material.parent !== item.id) {
                            await this.intoMaterial(item, material, index)
                        }
                    }
                    const fetchData = this.fetchData.filter(
                        item => item.parent_id
                    )
                    if (fetchData.length) {
                        this.fetch(fetchData)
                    }
                }
                this.isDragOver = false
                this.isDragOverElem = false

                this.broadCastMaterial.send(this.materialsList)
                this.$emit('change-dragged', false)
            },
            onDragOver() {
                this.isDragOver = true
            },
            onDragLeave() {
                this.isDragOver = false
            },
            onDragOverElem() {
                this.isDragOverElem = true
            },
            onDragLeaveElem() {
                this.isDragOverElem = false
            },
            onDragEnd() {
                this.isDragOver = false
                this.isDragOverElem = false
                this.$emit('change-dragged', false)
            },
            openPicker(e) {
                if (
                    !this.specialLinks.includes(this.data.name) &&
                    this.current_user.is_editor_material &&
                    !this.isSimpleLink
                ) {
                    this.$nextTick(() => {
                        this.$store.dispatch('sidebar/setPicker', {
                            opened: false
                        })
                        const coords = e.target.getBoundingClientRect()
                        const pickerElem = document.getElementById(
                            'sidebar_picker'
                        )
                        const backgroundPickerElem = document.getElementById(
                            'sidebar_picker-background'
                        )
                        pickerElem.style.top = `${coords.y + 570 > window.screen.height ? coords.y - 425 : coords.y + 25}px`
                        pickerElem.style.left = `${coords.x + 50}px`
                        backgroundPickerElem.style.height = `${document.body.clientHeight}px`
                        backgroundPickerElem.style.width = `${document.body.clientWidth}px`
                        this.$store.dispatch('sidebar/setPicker', {
                            opened: true,
                            id: this.data.id,
                            content_type: 'material'
                        })
                    })
                }
            },
            disableBg(e) {
                const elem = e.target.closest('.tree-header__link')
                elem.classList.add('tree-header__link--without-bg')
            },
            enableBg(e) {
                const elem = e.target.closest('.tree-header__link')
                elem.classList.remove('tree-header__link--without-bg')
            }
        },
        mounted() {
            const { supported, data, send } = useBroadcastChannel('composable-nav-tree');
            this.broadCastMaterial = {
                supported: supported,
                data: data,
                send: send
            }

            if (this.isAdmin === false) {
                const editorMaterialList = this.current_user.is_sub_editor_material

                if (editorMaterialList !== false) {
                    const isExcludeMaterial = editorMaterialList.length > 0 ? editorMaterialList.find((id) => id === parseInt(this.data.id)) : undefined

                    this.isPowersAdmin = (isExcludeMaterial === undefined)
                }
            } else {
                this.isPowersAdmin = this.isAdmin
            }

            eventBus.$on('change-emoji', data => {
                if (data.id === this.data.id) {
                    this.data.emoji = data.icon || null
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';
    .sidebar-tree {
        &__wrap {
            &--opened {
                & > .dropdown__header .tree-header__expand-icon {
                    transform: rotate(90deg) !important;
                }
            }
        }
    }
    .tree-header {
        position: relative;
        display: flex;
        align-items: center;
        &:hover {
            .tree-header__add {
                display: flex;
                opacity: 1;
            }
        }
        &--root {
            .tree-header__link {
                &:hover {
                    background: transparent !important;
                }
            }
        }
        &__icon {
            cursor: pointer;
        }
        &__expand {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            left: 10px;
            &::before {
                display: none;
                content: '';
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                height: 20px;
                width: 20px;
                background: #616575;
                border-radius: 2px;
                transition: background $ease;
            }
            &-icon {
                @include icon($sidebar-nav-expand, 10px);
                transform: rotate(0);
                transition: transform $ease;
            }
            &:hover {
                &::before {
                    display: block;
                }
            }
        }
        &__link {
            position: relative;
            width: 95%;
            padding: 10px 10px 10px 20px;
            display: flex;
            align-items: baseline;
            color: #fff;
            border-radius: 4px;
            cursor: pointer;
            &-background {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-radius: 4px;
                overflow: hidden;
                border: 1px solid #fff;
                opacity: 0;
                transition: opacity 100ms ease;
                &--drop {
                    opacity: 1;
                }
            }
            &:not(&--without-bg) {
                &:hover {
                    background-color: #616575;
                }
            }
        }
        &__title {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 10px;
            word-break: break-word;
            width: 100%;
            color: #fff;
            z-index: 1;
        }
        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            z-index: 1;
            &:not(&--root) {
                padding: 3px;
                &:hover {
                    background-color: #616575;
                    border-radius: 2px;
                    transition: background-color $ease;
                }
            }
        }
        &__hr {
            position: absolute;
            width: 95%;
            transform: translateY(-50%);
            opacity: 0;
            align-self: center;
            left: 0;
            top: 0;
            margin: 0;
            height: 1px;
            background: #fff;
            transition: opacity 100ms ease, visibility 100ms ease;
            &-round {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 0;
                transform: translateY(-50%);
                background: #fff;
            }

            &--hover {
                opacity: 1;
            }
        }
        &__dropped-zone {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            width: 95%;
            height: 30px;
            transform: translateY(-50%);
            visibility: hidden;
            z-index: 1;
        }
        &__add {
            border: none;
            margin-left: 10px;
            align-items: center;
            display: flex;
            opacity: 0;
            justify-content: center;
            padding: 5px;
            background-color: #828798;
            border-radius: 4px;
            cursor: pointer;
            z-index: 1;
            &:hover {
                opacity: 1;
                transition: all 0.05s ease-in;
                background-color: darken(#828798, 5%);
            }
        }
        &__img {
            $icon-size: 13px;
            width: $icon-size;
            height: $icon-size;
            display: block;
        }
        &__emoji {
            $icon-size: 16px;
            width: $icon-size;
            height: $icon-size;
            display: flex;
            align-items: center;
            justify-content: center;
            .emoji-mart-emoji {
                display: flex !important;
                font-size: 15px;
                line-height: 15px;
            }
        }
    }
</style>
