var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-tree__wrap"},[(_vm.broadCastMaterial.data.value)?_c('p',[_vm._v(_vm._s(true))]):_vm._e(),_c('dropdown',{key:_vm.data.name,attrs:{"active-class":"sidebar-tree__wrap--opened","opened":_vm.isOpened,"styles":{
            transformOrigin: 'top',
            transition: 'transform .4s ease-in-out'
        },"animation":"slide","on-first-open":_vm.loadAll},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tree-header',{staticClass:"tree-header--root",attrs:{"data":_vm.data},on:{"toggleDropdown":_vm.toggleDropdown}})]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"sidebar__nav-dropdown"},[_c('tree-header',{staticClass:"ml-20",attrs:{"data":{
                        title: 'Избранное',
                        link: '/knowledge/favourites',
                        emoji: 'star'
                    },"is-simple-link":true}}),(_vm.isLoaded && _vm.isAdmin)?_c('tree-header',{staticClass:"ml-20",staticStyle:{"margin-bottom":"10px"},attrs:{"data":{
                        title: 'Устаревшее',
                        link: '/knowledge/old',
                        emoji: 'hourglass'
                    },"is-simple-link":true}}):_vm._e(),_vm._l((_vm.materialsList),function(item,index){return _c('tree-item',{key:("material_" + (item.id)),attrs:{"is_material":true,"data":item,"materialIndex":index,"isDragged":_vm.isDragged},on:{"change-dragged":_vm.changeDragged}})})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }