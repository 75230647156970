<template>
    <div class="sidebar-tree__wrap">
        <p v-if="broadCastMaterial.data.value">{{ true }}</p>
        <dropdown
            active-class="sidebar-tree__wrap--opened"
            :opened="isOpened"
            :key="data.name"
            :styles="{
                transformOrigin: 'top',
                transition: 'transform .4s ease-in-out'
            }"
            animation="slide"
            :on-first-open="loadAll"
        >
            <template v-slot:header>
                <tree-header
                    :data="data"
                    class="tree-header--root"
                    @toggleDropdown="toggleDropdown" />
            </template>
            <template v-slot:body>
                <div class="sidebar__nav-dropdown">
                    <tree-header
                        class="ml-20"
                        :data="{
                            title: 'Избранное',
                            link: '/knowledge/favourites',
                            emoji: 'star'
                        }"
                        :is-simple-link="true"
                    />
                    <tree-header
                        v-if="isLoaded && isAdmin"
                        class="ml-20"
                        :data="{
                            title: 'Устаревшее',
                            link: '/knowledge/old',
                            emoji: 'hourglass'
                        }"
                        :is-simple-link="true"
                        style="margin-bottom: 10px"
                    />
                    <tree-item
                        v-for="(item, index) in materialsList"
                        :is_material="true"
                        :key="`material_${item.id}`"
                        :data="item"
                        :materialIndex="index"
                        :isDragged="isDragged"
                        @change-dragged="changeDragged"
                    />
                </div>
            </template>
        </dropdown>
    </div>
</template>

<script>
import session from '@/api/session';
import { eventBus } from '@/main';
import { mapGetters, mapMutations, mapState } from 'vuex';
import NavLink from '@/components/sidebar/navigation/NavLink';
import TreeItem from '@/components/sidebar/tree/TreeItem';
import TreeHeader from '@/components/sidebar/tree/TreeHeader';
import Dropdown from '@/components/Dropdown';

export default {
    name: 'NavTree',
    components: {
        NavLink,
        TreeItem,
        TreeHeader,
        Dropdown
    },
    data() {
        return {
            is_admin: true,
            copy_rubrics: [],
            rubrics: [],
            list: [],
            isOpened: false,
            isLoaded: false,
            isDragged: false,
            broadCastMaterial: {
                supported: null,
                data: { value: null },
                send: null
            }
        };
    },
    computed: {
        ...mapGetters('default_data', ['isAdmin']),
        ...mapState('knowledge_base', ['materialsList'])
    },
    props: {
        data: {
            type: Object
        },
        object_id: {
            required: false
        },
        url_list: {
            type: String,
            default: '/api/v1/knowledge-base/'
        }
    },
    methods: {
        toggleDropdown() {
          this.isOpened = !this.isOpened;
        },
        async loadAll() {
            // await this.$store.dispatch('knowledge_base/setMaterialsList');
            this.isLoaded = true;
        },
        changeDragged(value) {
            this.isDragged = value;
        }
    }
};
</script>

<style scoped></style>
