var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-header"},[_c('div',{staticClass:"tree-header__hr",class:{
            'tree-header__hr--hover': _vm.isDragOver
        }},[_c('div',{staticClass:"tree-header__hr-round"})]),_c('div',{staticClass:"tree-header__dropped-zone",style:({ visibility: _vm.isDragged ? 'visible' : 'hidden' }),on:{"drop":function($event){return _vm.onDrop($event, _vm.data, _vm.materialIndex)},"dragover":function($event){$event.preventDefault();return _vm.onDragOver($event)},"dragleave":function($event){return _vm.onDragLeave($event)},"dragenter":function($event){$event.preventDefault();}}}),_c('div',{staticClass:"tree-header__expand",style:({
            visibility:
                _vm.specialLinks.includes(_vm.data.name) || _vm.data.have_children
                    ? 'visible'
                    : 'hidden'
        }),on:{"click":function($event){return _vm.$emit('toggleDropdown')}}},[_c('i',{staticClass:"tree-header__expand-icon"})]),_c('span',{staticClass:"tree-header__link",attrs:{"draggable":_vm.data.id && _vm.data.title && _vm.isPowersAdmin},on:{"dragstart":function($event){_vm.startDrag($event, _vm.data, _vm.materialIndex)
            _vm.disableBg()},"dragover":function($event){$event.preventDefault();return _vm.onDragOverElem($event)},"dragleave":function($event){return _vm.onDragLeaveElem($event)},"dragend":function($event){return _vm.onDragEnd()},"drop":function($event){return _vm.onDrop($event, _vm.data, _vm.materialIndex)},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"tree-header__link-background",class:{
                'tree-header__link-background--drop':
                    _vm.isDragOverElem && _vm.data.id && _vm.data.parent
            }}),_c('div',{staticClass:"tree-header__icon",class:{
                'tree-header__icon--root': _vm.specialLinks.includes(_vm.data.name)
            },on:{"click":function($event){$event.stopPropagation();return _vm.openPicker($event)},"mouseover":_vm.disableBg,"mouseleave":_vm.enableBg}},[(_vm.data.required)?_c('div',{staticClass:"tree-header__emoji"},[_c('icon',{attrs:{"width":"12","height":"14"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0 0.5C0 0.223857 0.223858 0 0.5 0H11.1667C11.4428 0 11.6667 0.223858 11.6667 0.5V13.5C11.6667 13.7761 11.4428 14 11.1667 14H0.5C0.223858 14 0 13.7761 0 13.5V0.5ZM5.83333 8.16667C5.189 8.16667 4.66667 7.64433 4.66667 7V3.5C4.66667 2.85567 5.189 2.33333 5.83333 2.33333C6.47766 2.33333 7 2.85567 7 3.5V7C7 7.64433 6.47767 8.16667 5.83333 8.16667ZM5.83333 9.625C5.189 9.625 4.66667 10.1473 4.66667 10.7917C4.66667 11.436 5.189 11.9583 5.83333 11.9583C6.47767 11.9583 7 11.436 7 10.7917C7 10.1473 6.47767 9.625 5.83333 9.625Z","fill":"#FBC04F"}})])],1):(_vm.data.emoji)?_c('div',{staticClass:"tree-header__emoji"},[_c('emoji',{attrs:{"emoji":_vm.data.emoji,"set":"apple","size":16}})],1):_c('img',{staticClass:"tree-header__img",attrs:{"src":_vm.imgUrl || _vm.icon}})]),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                !_vm.isDragged
                    ? {
                          content: _vm.data.title,
                          placement: 'top',
                          offset: 10
                      }
                    : null
            ),expression:"\n                !isDragged\n                    ? {\n                          content: data.title,\n                          placement: 'top',\n                          offset: 10\n                      }\n                    : null\n            "}],staticClass:"tree-header__title",attrs:{"tag":"a","to":_vm.data.link || ("/knowledge/article/" + (_vm.data.id)),"draggable":"false"}},[_vm._v(" "+_vm._s(_vm.data.title || _vm.data.name)+" ")]),(!_vm.isSimpleLink && _vm.isPowersAdmin)?_c('button',{staticClass:"tree-header__add",attrs:{"disabled":_vm.triggerAddDisabled,"to":("/knowledge/add/" + (_vm.data.id))},on:{"click":_vm.addMaterial}},[_c('icon',{attrs:{"width":"10","height":"10"}},[_c('path',{attrs:{"d":"M1 5H9","stroke":"white","stroke-width":"2","stroke-linecap":"round"}}),_c('path',{attrs:{"d":"M5 9L5 1","stroke":"white","stroke-width":"2","stroke-linecap":"round"}})])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }