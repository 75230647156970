<template>
    <div
        class="tree-item"
        :class="{
            'd-none': !isVisible && !data.published
        }"
    >
        <tree-header
            :data="data"
            :materialIndex="materialIndex"
            :isDragged="isDragged"
            @change-dragged="$emit('change-dragged', $event)"
            v-if="!data.have_children"
            :is_material="true"
            style="margin-left: 20px;"
            :class="{
                'tree-header--draft': isVisible && !data.published
            }"
        />
        <dropdown
            v-else
            style="padding-left: 20px;"
            active-class="sidebar-tree__wrap--opened"
            :opened="opened"
            :key="`${data.title}_${data.id}_${data.active}`"
            :styles="{
                transformOrigin: 'top',
                transition: 'transform .4s ease-in-out'
            }"
            animation="slide"
            :on-first-open="loadChildren"
        >
            <template v-slot:header>
                <tree-header
                    :data="data"
                    :materialIndex="materialIndex"
                    :isDragged="isDragged"
                    @toggleDropdown="toggleDropdown"
                    @change-dragged="$emit('change-dragged', $event)"
                    :is_material="true"
                    :class="{
                        'tree-header--draft':
                            isVisible && !data.published
                    }"
                />
            </template>
            <template v-slot:body>
                <div class="sidebar__nav-dropdown">
                    <tree-item
                        v-for="(child, index) in data.children"
                        :key="`child_${child.id}`"
                        :data="child"
                        :materialIndex="index"
                        :is_material="true"
                        :isDragged="isDragged"
                        @change-dragged="$emit('change-dragged', $event)"
                    />
                </div>
            </template>
        </dropdown>
    </div>
</template>

<script>
import TreeHeader from '@/components/sidebar/tree/TreeHeader'
import Dropdown from '@/components/Dropdown'
import session from '@/api/session'
import { mapGetters } from 'vuex'

export default {
  name: 'TreeItem',
  components: { Dropdown, TreeHeader },
  data() {
      return {
        opened: false,
        isVisible: false
      }
  },
  props: ['data', 'materialIndex', 'is_material', 'isDragged'],
  computed: {
    ...mapGetters('default_data', ['isEditorMaterial', 'current_user'])
  },
  methods: {
    toggleDropdown() {
      this.opened = !this.opened
    },
    async loadChildren () {
      if (!this.data.children.length && this.data.have_children) {
        const request = await session.get(
                    `/api/v1/knowledge-base/${this.data.id}/children/`
        )
        this.$store.dispatch('knowledge_base/addChildren', {
          children: request.data,
          parent: this.data.id
        })
      }
    }
  },
  created () {
    if (!this.data.children) {
      this.data.children = []
    }
    if (!this.data.list) {
      this.data.list = []
    }

    this.isVisible = this.isEditorMaterial || this.current_user
  }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.tree-header {
    &--draft {
        opacity: 0.5;
    }
}
</style>
